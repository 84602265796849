import { defineStore } from 'pinia';
import { EventsResponse, TicketsResponse } from '@ambassy/schema';

export const useModelActionTicketsStore = defineStore(
  'organisms-model-action-tickets',
  {
    state: () => ({
      tickets: [] as TicketsResponse,
      isLoading: true,
      error: '',
    }),
    actions: {
      startLoading() {
        this.isLoading = true;
      },
      stopLoading() {
        this.isLoading = false;
      },
      clearTickets() {
        this.tickets = [];
      },
      setTickets(events: TicketsResponse) {
        this.tickets = events;
      },
      setError(error: string) {
        this.error = error;
      },
      clearError() {
        this.error = '';
      },
    },
  },
);
