<template>
  <div>
    <div class="relative mt-1 flex items-center space-x-1">
      <input
        type="datetime-local"
        :class="[
          error
            ? 'border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
            : '',
          'block appearance-none border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm w-full rounded-md shadow-sm',
        ]"
        :value="inputDate"
        :modelValue="inputDate"
        @input="setDate"
        :required="required"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :placeholder="placeholder"
        ref="input"
        :min="inputMinDate"
      />
      <XMarkIcon
        class="h-5 w-5 text-gray-700"
        @click="clear"
        v-if="inputDate"
      />
    </div>
    <div class="mt-1 text-xs text-gray-400">
      {{ locale }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';

const props = defineProps<{
  modelValue?: Date | string | number | null;
  required?: boolean;
  autofocus?: boolean;
  autocomplete?: string;
  placeholder?: string;
  error?: string | string[];
  minDate?: Date;
}>();

const dateToDateTimeString = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

const emits = defineEmits<{
  (event: 'update:modelValue', ...args: any[]): void;
}>();

const input = ref<InstanceType<typeof HTMLInputElement> | null>(null);

const getDate = () => {
  if (!props.modelValue) {
    return '';
  }

  if (props.minDate && props.minDate > props.modelValue) {
    emits('update:modelValue', props.minDate);
    return dateToDateTimeString(props.minDate);
  }

  if (props.modelValue) {
    const date = new Date(props.modelValue);
    return dateToDateTimeString(date);
  }

  return '';
};

const initialDate = getDate();

const inputDate = computed(getDate);

onMounted(() => {
  if (props.autofocus && input.value) {
    input.value.focus();
  }
  if (input.value && initialDate) {
    input.value.value = initialDate;
  }
});

defineExpose({ focus: () => input.value?.focus() });

const parseDate = (date: string) => {
  if (!date) {
    return null;
  }
  // parse date time string to date
  const [year, month, day] = date.split('-');
  const [hour, minute] = date.split('T')[1].split(':');
  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute),
  );
};

const inputMinDate = computed(() => {
  if (props.minDate) {
    const date = new Date(props.minDate);
    return dateToDateTimeString(date);
  }
  return '';
});

const setDate = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emits('update:modelValue', parseDate(target.value));
};

const locale = computed(() => {
  const locale = new Date().toString().match(/([A-Z]+[+-][0-9]+)/);
  if (locale && locale.length > 1) {
    return locale[1];
  }
  return '-';
});

const clear = () => {
  (input.value as HTMLInputElement).value = '';
  emits('update:modelValue', null);
};
</script>
