import { defineStore } from 'pinia';
import {
  AmbassadorTicketQuestPerformanceResponse,
  AmbassadorTicketQuestsPerformanceResponse,
  ClientErrorInterface,
} from '@ambassy/schema';

type GroupedTickets = { letter: string; amount: number; soldAt: Date | null }[];
const groupTickets = (
  tickets: AmbassadorTicketQuestPerformanceResponse['tickets'],
): GroupedTickets => {
  if (!tickets) return {} as GroupedTickets;
  const grouped = tickets.reduce(
    (acc, ticket) => {
      const key = ticket.orderId;
      if (!acc[key]) {
        acc[key] = {
          letter: ticket.letter,
          amount: 0,
          soldAt: ticket.soldAt ? new Date(ticket.soldAt) : null,
        };
      }
      acc[key].amount += 1;
      return acc;
    },
    {} as Record<string, GroupedTickets[number]>,
  );
  let values = Object.values(grouped);
  values = values.sort(
    (a, b) => (b.soldAt?.getTime() ?? 0) - (a.soldAt?.getTime() ?? 0),
  );
  return values;
};

export const useTicketsSoldStore = defineStore('ticketsSoldStore', {
  state: () => ({
    sells: {
      sold: 0,
      points: 0,
      tickets: [],
    } as AmbassadorTicketQuestsPerformanceResponse,
    isLoading: true,
    errorMessage: '',
    hidden: false,
  }),
  actions: {
    setSells(sells: AmbassadorTicketQuestsPerformanceResponse) {
      this.sells = sells;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    hideTickets() {
      this.hidden = true;
    },
  },
  getters: {
    grouped: (state) => {
      return groupTickets(state.sells.tickets);
    },
  },
});
