<template>
  <InputTitle>Wait until</InputTitle>
  <DateTimeInput v-model="settings.waitUntil" class="mt-4" />
  <InputError :message="errors" />
  <div class="flex space-x-8 mt-6">
    <SecondaryButton class="w-full" @secondary-button-clicked="emits('back')">
      Back
    </SecondaryButton>
    <PrimaryButton class="w-full" @primary-button-clicked="confirm">
      Add condition
    </PrimaryButton>
  </div>
</template>
<script setup lang="ts">
import InputTitle from '../../../atoms/Input/InputTitle.vue';
import DateTimeInput from '../../../atoms/Input/DateTimeInput.vue';
import { CreateActionSettings } from '@ambassy/schema';
import PrimaryButton from '../../../atoms/Buttons/PrimaryButton.vue';
import SecondaryButton from '../../../atoms/Buttons/SecondaryButton.vue';
import InputError from '../../../atoms/Input/InputError.vue';
import { ref } from 'vue';

const settings = defineModel<CreateActionSettings>({
  default: { waitUntil: new Date().toISOString() },
});

const emits = defineEmits<{
  (event: 'add-condition', settings: CreateActionSettings): void;
  (event: 'back'): void;
}>();

const errors = ref<string[]>([]);

const validate = (): string[] | true => {
  if (settings.value.waitUntil) {
    return true;
  }

  return ['Please select a date'];
};

const confirm = () => {
  errors.value = [];
  const validation = validate();
  if (validation === true) {
    emits('add-condition', settings.value);
  } else {
    errors.value = validation;
  }
};
</script>
