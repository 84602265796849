import { defineStore } from 'pinia';
import { EventsResponse, TicketServiceProviderResponse } from '@ambassy/schema';

export const useModelActionEventsStore = defineStore(
  'organisms-model-action-events',
  {
    state: () => ({
      events: [] as EventsResponse,
      selectedEvent: null as string | null,
      ticketServiceProviders: {} as TicketServiceProviderResponse,
      selectedTsp: null as keyof TicketServiceProviderResponse | null,
      isLoading: true,
      error: '',
    }),
    actions: {
      startLoading() {
        this.isLoading = true;
      },
      stopLoading() {
        this.isLoading = false;
      },
      clearEvents() {
        this.events = [];
        this.selectedEvent = null;
      },
      setEvents(events: EventsResponse) {
        this.events = events;
      },
      setTicketServiceProviders(tsps: TicketServiceProviderResponse) {
        this.ticketServiceProviders = tsps;
      },
      setError(error: string) {
        this.error = error;
      },
      clearError() {
        this.error = '';
      },
    },
  },
);
